@font-face {
  font-family: "almoni-tzar-light"; /*Can be any text*/
  src: local("almoni-tzar-light-aaa"),
    url("./fonts/almoni-tzar-light-aaa.otf") format("opentype");
}
@font-face {
  font-family: "almoni-tzar"; /*Can be any text*/
  src: local("almoni-tzar-regular-aaa"),
    url("./fonts/almoni-tzar-regular-aaa.otf") format("opentype");
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  color: white;
  font-family: "almoni-tzar";
}

.container {
  position: absolute;
  top: 38%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 55%;
  width: 100%;
}

.coming-soon-title {
  margin: 0px;
  letter-spacing: 0.15rem;
  font-weight: 500;
  letter-spacing: 0.45rem;
  font-size: 3rem;
  position: relative;
  top: -15%;
}

.join-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "almoni-tzar-light";
}

.join__text {
  font-size: 1.5rem;
  letter-spacing: 0.15rem;
  font-weight: 500;
  margin: 0px;
}

.join__link {
  color: white;
  margin: 0.5rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
  font-size: 1.8rem;
  font-family: "almoni-tzar-light";
}
.join__link h3 {
  margin: 0px;
  font-weight: 500;
}

.form-input {
  border: none;
  background: transparent;
  border-bottom: 1px solid white;
  width: 70%;
  font-family: "almoni-tzar";
  color: white;
  font-size: 18px;
}
.form-input::placeholder {
  color: white;
}
.form-input:-ms-input-placeholder {
  color: white;
}
.form-input::-ms-input-placeholder {
  color: white;
}
.subscribe-form {
  display: flex;
  width: 30rem;
  font-family: "almoni-tzar-light";
}

.btn__submit {
  background: transparent;
  border: none;
  border-bottom: 1px white solid;
  color: white;
  margin-left: 1rem;
  font-family: "almoni-tzar";
  font-size: 18px;
}

.logo-picture {
  height: 8rem;
  width: 100%;
}
.icons {
  display: inline-flex;
  align-items: center;
}

.icons h3 {
  font-weight: 500;
  margin: 0.5rem;
  font-size: 1.3rem;
  text-decoration: none;
}

.player-wrapper {
  overflow: hidden;
  align-content: center;
}

.react-player {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.insta__link {
  color: white;
  margin: 0.5rem;
  font-weight: 500;
  letter-spacing: 0.05rem;
  font-size: 1.2rem;
  width: 100%;
  position: relative;
  bottom: -15%;
  text-decoration: none;
}
.modal {
  direction: rtl;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.118);
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(3px);
  color: black;
}
.modal-content {
  width: 20rem;
  height: 400px;
  background-color: azure;
  border-radius: 5%;
}
.modal-body {
  padding: 10px;
  text-align: initial;
}

.btn__modal {
  background: transparent;
  border: none;
  border-bottom: 1px rgb(0, 0, 0) solid;
  color: rgb(0, 0, 0);
  margin-left: 1rem;
  font-family: "almoni-tzar";
  font-size: 18px;
}
.page {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(77, 79, 68);
  color:white
}

.login-form {
  height: 440px;
  width: 300px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.delete-form  {
  height: 440px;
  width: 300px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 580px) {
  .coming-soon-title {
    font-size: 1.3em;
    line-height: 22px;
    letter-spacing: 0.2em;
    top: -10%;
    font-family: "almoni-tzar";
  }

  .subscribe-form {
    width: 90%;
  }
  .join-wrapper {
    position: relative;
    top: -16%;
  }

  .join__text {
    font-size: 1.2rem;
    letter-spacing: 0.13rem;
  }
  .join__link {
    font-size: 1.2rem;
    letter-spacing: 0.13rem;
  }
  .logo-picture {
    height: auto;
    width: 80%;
  }
}
